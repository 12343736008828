<template>
	<div class="wrapper">
		<side-bar>
			<template slot="links">
				<sidebar-link
					to="/test/dashboard"
					:name="$t('sidebar.dashboard')"
					icon="tim-icons icon-chart-pie-36"
				/>
				<sidebar-link
					to="/test/icons"
					:name="$t('sidebar.icons')"
					icon="tim-icons icon-atom"
				/>
				<sidebar-link
					to="/test/maps"
					:name="$t('sidebar.maps')"
					icon="tim-icons icon-pin"
				/>
				<sidebar-link
					to="/test/notifications"
					:name="$t('sidebar.notifications')"
					icon="tim-icons icon-bell-55"
				/>
				<sidebar-link
					to="/test/profile"
					:name="$t('sidebar.userProfile')"
					icon="tim-icons icon-single-02"
				/>
				<sidebar-link
					to="/test/table-list"
					:name="$t('sidebar.tableList')"
					icon="tim-icons icon-puzzle-10"
				/>
				<sidebar-link
					to="/test/typography"
					:name="$t('sidebar.typography')"
					icon="tim-icons icon-align-center"
				/>
				<sidebar-link
					to="/test/dashboard?enableRTL=true"
					:name="$t('sidebar.rtlSupport')"
					icon="tim-icons icon-world"
				/>
			</template>
		</side-bar>
		<div class="main-panel">
			<top-navbar></top-navbar>

			<dashboard-content @click.native="toggleSidebar">
			</dashboard-content>

			<content-footer></content-footer>
		</div>
	</div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import MobileMenu from './MobileMenu';
export default {
	components: {
		TopNavbar,
		ContentFooter,
		DashboardContent,
		MobileMenu
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		}
	}
};
</script>
