/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from './../router/';
import App from './appController';
import VueFormGenerator from 'vue-form-generator';

Vue.use(Vuex);
Vue.config.productionTip = false;

async function async(fn) {
    try {
        const data = await fn;
        return data;
    } catch (error) { }
}
const store = new Vuex.Store({
    state() {
        return {
            user: false,
            comunications: false,
            userApps: [],
            auth: false,
            authed: false,
            notification: {},
            users: [],
            spidTicket: false,
            requiredUpload: false,
            shownType: {},
            service: {},
            services: [],
            app: {},
            userApp: {},
            api: '',
            updateDataForm: {},
            apps: [{
                label: 'concorso',
                sessione: '',
                code: 'coa2021',
                title: 'Concorso pubblico, per esami, per l’ammissione di quattrocentoquarantotto borsisti al corso-concorso selettivo di formazione - edizione 2021 per il conseguimento dell’abilitazione richiesta ai fini dell’iscrizione di trecentoquarantacinque Segretari comunali nella fascia iniziale dell’Albo nazionale dei Segretari comunali e provinciali',
                openTime: '10/11/2021',
                slug: 'albo-nazionale-segretari',
                type: 'AppEnrollment',
                closeTime: '09/12/2021',
                public: true,
                access: true,
                active: true,
                accessMethod: 'spid',
                formTitle: '',
                status: false,
                valid: false,
                model: {
                    cittadinanza: '',
                    iscrizioneListeElettorali: '',
                    listaElettorale: '',
                    ragioniListaElettorale: '',
                    obblighiMilitari: '',
                    checkCondannePenali: '',
                    condannePenali: '',
                    idoneitaFisica: '',
                    destituzione: '',
                    interdizione: '',
                    titoloDiStudio: '',
                    ateneo: '',
                    annoConseguimento: '',
                    luogoConseguimento: '',
                    titoliPreferenza: [],
                    nFigli: '',
                    esecuzioneBonifico: '',
                    trattamentoDati: '',
                    indirizzoResidenza: '',
                    statoResidenza: '',
                    comuneResidenza: '',
                    provinciaResidenza: '',
                    statoDomicilio: '',
                    checkDomicilio: '',
                    indirizzoDomicilio: '',
                    comuneDomicilio: '',
                    provinciaDomicilio: '',
                    email: '',
                    repeatEmail: '',
                    pec: '',
                    repeatPec: '',
                    phone: '',
                    riserva: '',
                    enteRiserva: '',
                    posizioneRiserva: '',
                    anniRiserva: '',
                    disabilita80: '',
                    disabilita: '',
                    ausilioTa: '',
                    documentazione: '',
                    consensoDisabilita: '',
                    provvedimento: ''
                },
                form: {
                    fields: [{
                        model: 'cittadinanza',
                        label: 'Il possesso della cittadinanza Italiana',
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        styleClasses: 'col-md-12 mb-4 mt-3 cittadinanza',
                        required: true,
                        validator: [
                            (value, field) => {
                                return field.accepted.includes(value) ?
                                    [
                                        /* '<span style="color:#3C9891;">Campo corretto</span>' */
                                    ] :
                                    ['<span style="color:#ec250d;">Dichiarazione obbligatoria</span>'];
                            }
                        ]
                    },
                    {
                        model: 'iscrizioneListeElettorali',
                        label: 'Di essere inscritto nelle liste elettorali',
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        required: true,
                        styleClasses: 'col-md-12 mt-4 iscrizioneListeElettorali',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'listaElettorale',
                        label: 'Comune di iscrizione',
                        type: 'textArea',
                        required: false,
                        styleClasses: 'col-md-12 mb-4 listaElettorale',
                        validator: [
                            (value, field, model) => {
                                if (model['iscrizioneListeElettorali'] == 'Dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'ragioniListaElettorale',
                        label: 'Motivi della mancata iscrizione o cancellazione dalle liste',
                        type: 'textArea',
                        styleClasses: 'col-md-12 mb-4 ragioniListaElettorale hidden',

                        rows: 2,
                        disabled: (value, field, model) => {
                            return model['iscrizioneListeElettorali'] == 'Dichiaro' ? true : false;
                        },
                        required: false,
                        validator: [
                            (value, field, model) => {
                                if (model['iscrizioneListeElettorali'] == 'Non dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'obblighiMilitari',
                        label: 'Di avere posizione regolare nei riguardi degli obblighi militari (per i cittadini soggetti a tale obbligo)',
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro', 'Non dichiaro'],
                        styleClasses: 'col-md-12 mb-4 my-4',
                        required: false,
                        hint: 'Selezionare Non dichiaro nel caso non si sia soggetti a tale obbligo',
                        validator: [
                            (value, field) => {
                                return field.accepted.includes(value) ?
                                    [
                                        /* '<span style="color:#3C9891;">Campo corretto</span>' */
                                    ] :
                                    ['<span style="color:#ec250d;">Dichiarazione obbligatoria</span>'];
                            }
                        ],
                        hint: 'Selezionare Non dichiaro nel caso non si sia soggetti a tale obbligo'
                    },
                    {
                        model: 'checkCondannePenali',
                        label: 'Di non aver riportato condanne penali, di non avere procedimenti penali pendenti e dinon essere stato sottoposto ad applicazione della pena ex articolo 444 c.p.p. In caso contrario, devono essere indicate le condanne riportate, anche se sia stata concessa amnistia, indulto, condono, perdono giudiziale, riabilitazione e sospensione della pena, devono essere specificati i carichi pendenti',
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        styleClasses: 'col-md-12 mt-4',
                        required: true,
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'condannePenali',
                        label: 'Indicare condanne Penali e/o procedimenti penali pendenti',
                        type: 'textArea',
                        styleClasses: 'col-md-12 mb-4 hidden',
                        rows: 4,
                        required: false,
                        validator: [
                            (value, field, model) => {
                                if (model['checkCondannePenali'] == 'Non dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ],
                        hint: 'Campo obbligatorio in caso di condanne Penali e/o procedimenti penali pendenti;'
                    },
                    {
                        model: 'idoneitaFisica',
                        label: "Possesso dell'idoneità fisica all'impiego",
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        styleClasses: 'col-md-12 my-4',
                        validator: [
                            (value, field) => {
                                return field.accepted.includes(value) ?
                                    [] :
                                    ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                            }
                        ]
                    },
                    {
                        model: 'titoloDiStudio',
                        label: 'Di essere in possesso del seguente titoli di studio',
                        type: 'select',
                        values: [
                            'Diploma di laurea in Giurisprudenza',
                            'Diploma di laurea in Economia e Commercio',
                            'Diploma di laurea in Scienze Politiche',
                            'Laurea in Giurisprudenza (22/S)',
                            'Laurea in Giurisprudenza (LMG-01)',
                            'Laurea in Programmazione e gestione delle politiche e dei servizi sociali (57/S)',
                            'Laurea in Relazioni internazioni (60/S)',
                            'Laurea in Relazioni internazioni (LM/52)',
                            'Laurea in Scienze della politica (70/S)',
                            'Laurea in Scienze della politica (LM-62)',
                            'Laurea in Scienze delle pubbliche amministrazioni (71/S)',
                            'Laurea in Scienze delle pubbliche amministrazioni (LM-63)',
                            'Laurea in Scienze delle economia (LM-56)',
                            'Laurea in Scienze delle economia (64/S)',
                            'Laurea in Scienze economico aziendali (84/S)',
                            'Laurea in Scienze economico aziendali (LM-77)',
                            'Laurea in Scienze per la cooperazione allo sviluppo(88/S)',
                            'Laurea in Scienze per la cooperazione allo sviluppo(LM-81)',
                            'Laurea in Servizio sociale e politiche sociali (LM-87)',
                            'Laurea in Sociologia (89/S)',
                            'Laurea in Sociologia e ricerca sociale (LM-88)',
                            'Laurea in studi Europei (99/S)',
                            'Laurea in studi Europei (LM-90)',
                            "Laurea in Teorie e tecniche della normazione e dell'informazione giuridica (102/S)",
                            'Laurea equipollente conseguita in Italia',
                            "Laurea equivalente conseguita all'estero",
                            "Laurea equipollente conseguita all'estero"
                        ],
                        required: true,
                        styleClasses: 'col-md-12 my-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'ateneo',
                        label: 'Ateneo presso il quale si è conseguito il titolo',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-4 mb-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'annoConseguimento',
                        label: 'Anno di conseguimento:',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-4 mb-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'luogoConseguimento',
                        label: 'Luogo di conseguimento:',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-4 mb-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'provvedimento',
                        label: 'Provvedimento di equipollenza/equivalenza:',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-12 my-4 hidden',
                        validator: [
                            (value, field, model) => {
                                if (
                                    (model['titoloDiStudio'] == "Laurea equivalente conseguita all'estero" ||
                                        model['titoloDiStudio'] == 'Laurea equipollente conseguita in Italia' ||
                                        model['titoloDiStudio'] ==
                                        "Laurea equipollente conseguita all'estero") &&
                                    (value == '' || value == undefined)
                                ) {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'titoliPreferenza',
                        label: 'Di essere in possesso di uno o più titoli di preferenza:',
                        type: 'checklist',
                        listBox: true,
                        values: [
                            'Gli insigniti di medaglia al valor militare',
                            'I mutilati ed invalidi di guerra ex combattenti',
                            'Mutilati ed invalidi per fatto di guerra',
                            'I mutilati ed invalidi per servizio nel settore pubblico e privato',
                            'Gli orfani di guerra',
                            'Gli orfani di caduti per fatto di guerra',
                            'Gli orfani di caduti per servizio nel settore pubblico e privato',
                            'I feriti in combattimento',
                            'Gli insigniti di croce di guerra o di altra attestazione speciale di merito di guerra nonché i capi di famiglia numerosa',
                            'I figli dei mutilati e degli invalidi di guerra ex combattenti',
                            'I figli dei mutilati e degli invalidi per fatto di guerra',
                            'I figli dei mutilati e degli invalidi per servizio nel settore pubblico e privato',
                            'I genitori vedovi non risposati, i coniugi non risposati e le sorelle ed i fratelli vedovi o non sposati dei caduti di guerra',
                            'I genitori vedovi non risposati, i coniugi non risposati e le sorelle ed i fratelli vedovi o non sposati dei caduti per fatto di guerra',
                            'I genitori vedovi non risposati, i coniugi non risposati e le sorelle ed i fratelli vedovi o non sposati dei caduti per servizio nel settore pubblico o privato',
                            'Coloro che abbiano prestato servizio militare come combattenti',
                            'Coloro che abbiano prestato lodevole servizio a qualunque titolo, per non meno di un anno, nell’amministrazione che ha indetto il concorso',
                            'I coniugati ed i non coniugati con riguardo al numero dei figli a carico',
                            'Gli invalidi e i mutilati civili',
                            'I militari volontari delle forze armate congedati senza demerito al termine della ferma o rafferma',
                            'Avere svolto, con esito positivo, l’ulteriore periodo di perfezionamento presso l’ufficio  per  il  processo  ai  sensi  dell’articolo  16-octies,  comma  1 -quater  del  decreto-legge  18  ottobre  2012,  n.  179  convertito,  con  modificazioni,  dalla  legge  17  dicembre              2012,  n.  221,  come  modificato  dall’articolo   50  del  decreto-legge  24  giugno  2014,  n. 90, convertito,  con modificazioni,  dalla  legge  11 agosto  2014, n. 114',
                            'Avere completato, con esito positivo, il tirocinio formativo presso gli uffici giudiziari ai sensi dell’articolo 37, comma 11, del decreto-legge 6 luglio 2011, n. 98, convertito, con modificazioni, dalla legge 15 luglio 2011, n. 111, pur non facendo parte dell’ufficio per il processo, così come indicato dall’articolo 16-octies, comma 1-quinquies del decreto-legge 18 ottobre 2012, n. 179 convertito, con modificazioni, dalla legge 17 dicembre 2012, n. 221, come modificato dall’articolo 50 del decreto- legge 24 giugno 2014, n. 90, convertito, con modificazioni, dalla legge 11 agosto 2014, n. 114',
                            'Aver prestato lodevole servizio nelle amministrazioni pubbliche',
                            'Aver svolto, con esito positivo, lo stage presso gli uffici giudiziari ai sensi dell’articolo 73, comma 14, del decreto-legge 21 giugno 2013, n. 69, convertito dalla legge 9 agosto 2013, n. 98'
                        ],
                        styleClasses: 'col-md-12 my-4'
                    },
                    {
                        model: 'nFigli',
                        label: 'numero di figli a carico:',
                        type: 'input',
                        inputType: 'number',
                        required: false,
                        styleClasses: 'col-md-4 mb-4 hidden',
                        validator: [
                            (value, field, model) => {
                                if (model['titoliPreferenza'].join().indexOf('figli a') > -1) {
                                    if (value == null || value == '' || isNaN(value)) {
                                        return [
                                            '<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'
                                        ];
                                    } else if (value < 1) {
                                        return ['<span style="color:#ec250d;">Formato non corretto</span>'];
                                    } else {
                                        return [];
                                    }
                                }
                            }
                        ]
                    },
                    {
                        model: 'destituzione',
                        label: "Di non essere stato destituito o dispensato dell'impiego presso una pubblica amministrazione, ovvero di non essere decaduto ovvero licenziato ai sensi della vigente normativa di legge e/o contrattuale dall'impiego stesso",
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        styleClasses: 'col-md-12 my-4',
                        validator: [
                            (value, field) => {
                                return field.accepted.includes(value) ?
                                    [] :
                                    ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                            }
                        ]
                    },
                    {
                        model: 'interdizione',
                        label: 'di non essere stato interdetto dai pubblici uffici in base a sentenza passata in giudicato',
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        styleClasses: 'col-md-12 my-4',
                        validator: [
                            (value, field) => {
                                return field.accepted.includes(value) ?
                                    [] :
                                    ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                            }
                        ]
                    },
                    {
                        model: 'esecuzioneBonifico',
                        label: "Di aver eseguito il bonifico relativo al pagamento dei diritti di segreteria di cui all'art. 3 comma 15 del bando, indicandonegli estremi (CRO) ",
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-12 my-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ],
                        hint: 'L’importo è fissato in 10,00 (dieci) euro da versare mediante bonifico bancario, utilizzando il codice IBAN IT23F0100003245348014244201 intestato alla Tesoreria dello Stato – Roma succursale, con la causale “cognome nome/codice fiscale/COA2021”'
                    },
                    {
                        model: 'trattamentoDati',
                        label: "Di autorizzare il Ministero dell'Interno - Dipartimento per gli affari interni e territoriali - Albo Nazionale dei segretari comunali e provinciali, al trattamento dei dati personali per le finalità di cui al presente bando, ai sensi del decreto legislativo 30 Giugno 2003, n.196, del regolamento europeo n. 2016/679 e del relativo decreto legislativo di adeguamento n.101 del 10 Agosto 2018",
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        required: true,
                        styleClasses: 'col-md-12 my-4',
                        validator: [
                            (value, field) => {
                                return field.accepted.includes(value) ?
                                    [] :
                                    ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                            }
                        ]
                    },
                    {
                        model: 'statoResidenza',
                        label: 'Di essere residente nel seguente Stato:',
                        type: 'select',
                        values: [
                            'Afghanistan',
                            'Albania',
                            'Algeria',
                            'Andorra',
                            'Angola',
                            'Anguilla',
                            'Antartide',
                            'Antigua e Barbuda',
                            'Arabia Saudita',
                            'Argentina',
                            'Armenia',
                            'Aruba',
                            'Australia',
                            'Austria',
                            'Azerbaigian',
                            'Bahamas',
                            'Bahrein',
                            'Bangladesh',
                            'Barbados',
                            'Belgio',
                            'Belize',
                            'Benin',
                            'Bermuda',
                            'Bhutan',
                            'Bielorussia',
                            'Birmania',
                            'Bolivia',
                            'Bosnia ed Erzegovina',
                            'Botswana',
                            'Brasile',
                            'Brunei',
                            'Bulgaria',
                            'Burkina Faso',
                            'Burundi',
                            'Cambogia',
                            'Camerun',
                            'Canada',
                            'Capo Verde',
                            'Ciad',
                            'Cile',
                            'Cina',
                            'Cipro',
                            'Citt',
                            'Colombia',
                            'Comore',
                            'Corea del Nord',
                            'Corea del Sud',
                            "Costa d'Avorio",
                            'Costa Rica',
                            'Croazia',
                            'Cuba',
                            'Cura',
                            'Danimarca',
                            'Dominica',
                            'Ecuador',
                            'Egitto',
                            'El Salvador',
                            'Emirati Arabi Uniti',
                            'Eritrea',
                            'Estonia',
                            'Etiopia',
                            'Figi',
                            'Filippine',
                            'Finlandia',
                            'Francia',
                            'Gabon',
                            'Gambia',
                            'Georgia',
                            'Georgia del Sud e isole Sandwich meridionali',
                            'Germania',
                            'Ghana',
                            'Giamaica',
                            'Giappone',
                            'Gibilterra',
                            'Gibuti',
                            'Giordania',
                            'Grecia',
                            'Grenada',
                            'Groenlandia',
                            'Guadalupa',
                            'Guam',
                            'Guatemala',
                            'Guernsey',
                            'Guinea',
                            'Guinea-Bissau',
                            'Guinea Equatoriale',
                            'Guyana',
                            'Guyana francese',
                            'Haiti',
                            'Honduras',
                            'Hong Kong',
                            'India',
                            'Indonesia',
                            'Iran',
                            'Iraq',
                            'Irlanda',
                            'Islanda',
                            'Isola Bouvet',
                            'Isola di Man',
                            'Isola di Natale',
                            'Isola Norfolk',
                            'Isole ',
                            'Isole BES',
                            'Isole Cayman',
                            'Isole Cocos (Keeling)',
                            'Isole Cook',
                            'F',
                            'Isole Falkland',
                            'Isole Heard e McDonald',
                            'Isole Marianne Settentrionali',
                            'Isole Marshall',
                            'Isole minori esterne degli Stati Uniti',
                            'Isole Pitcairn',
                            'Isole Salomone',
                            'Isole Vergini britanniche',
                            'Isole Vergini americane',
                            'Israele',
                            'Italia',
                            'Jersey',
                            'Kazakistan',
                            'Kenya',
                            'Kirghizistan',
                            'Kiribati',
                            'Kuwait',
                            'Laos',
                            'Lesotho',
                            'Lettonia',
                            'Libano',
                            'Liberia',
                            'Libia',
                            'Liechtenstein',
                            'Lituania',
                            'Lussemburgo',
                            'Macao',
                            'Macedonia',
                            'Madagascar',
                            'Malawi',
                            'Malesia',
                            'Maldive',
                            'Mali',
                            'Malta',
                            'Marocco',
                            'Martinica',
                            'Mauritania',
                            'Mauritius',
                            'Mayotte',
                            'Messico',
                            'Micronesia',
                            'Moldavia',
                            'Mongolia',
                            'Montenegro',
                            'Montserrat',
                            'Mozambico',
                            'Namibia',
                            'Nauru',
                            'Nepal',
                            'Nicaragua',
                            'Niger',
                            'Nigeria',
                            'Niue',
                            'Norvegia',
                            'Nuova Caledonia',
                            'Nuova Zelanda',
                            'Oman',
                            'Paesi Bassi',
                            'Pakistan',
                            'Palau',
                            'Palestina',
                            'Panam',
                            'Papua Nuova Guinea',
                            'Paraguay',
                            'Per',
                            'Polinesia Francese',
                            'Polonia',
                            'Porto Rico',
                            'Portogallo',
                            'Monaco',
                            'Qatar',
                            'Regno Unito',
                            'RD del Congo',
                            'Rep. Ceca',
                            'Rep. Centrafricana',
                            'Rep. del Congo',
                            'Rep. Dominicana',
                            'Riunione',
                            'Romania',
                            'Ruanda',
                            'Russia',
                            'Sahara Occidentale',
                            'Saint Kitts e Nevis',
                            'Santa Lucia',
                            "Sant'Elena, Ascensione e Tristan da Cunha",
                            'Saint Vincent e Grenadine',
                            'Saint-Barth',
                            'Saint-Martin',
                            'Saint-Pierre e Miquelon',
                            'Samoa',
                            'Samoa Americane',
                            'San Marino',
                            'S',
                            'Senegal',
                            'Serbia',
                            'Seychelles',
                            'Sierra Leone',
                            'Singapore',
                            'Sint Maarten',
                            'Siria',
                            'Slovacchia',
                            'Slovenia',
                            'Somalia',
                            'Spagna',
                            'Sri Lanka',
                            'Stati Uniti',
                            'Sudafrica',
                            'Sudan',
                            'Sudan del Sud',
                            'Suriname',
                            'Svalbard e Jan Mayen',
                            'Svezia',
                            'Svizzera',
                            'Swaziland',
                            'Taiwan',
                            'Tagikistan',
                            'Tanzania',
                            'Terre australi e antartiche francesi',
                            "Territorio britannico dell'oceano Indiano",
                            'Thailandia',
                            'Timor Est',
                            'Togo',
                            'Tokelau',
                            'Tonga',
                            'Trinidad e Tobago',
                            'Tunisia',
                            'Turchia',
                            'Turkmenistan',
                            'Turks e Caicos',
                            'Tuvalu',
                            'Ucraina',
                            'Uganda',
                            'Ungheria',
                            'Uruguay',
                            'Uzbekistan',
                            'Vanuatu',
                            'Venezuela',
                            'Vietnam',
                            'Wallis e Futuna',
                            'Yemen',
                            'Zambia',
                            'Zimbabwe'
                        ],
                        required: true,
                        styleClasses: 'col-md-12 my-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'indirizzoResidenza',
                        label: 'Di essere in possesso del seguente indirizzo di residenza:',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-12 mb-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'comune di residenza',
                        label: 'Comune Residenza',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-6 mb-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'provinciaResidenza',
                        label: 'provincia Residenza',
                        hint: "In caso di residenza all'estero selezionare come provincia la sigla EE",
                        type: 'select',
                        values: [
                            'AG',
                            'AL',
                            'AN',
                            'AO',
                            'AP',
                            'AQ',
                            'AR',
                            'AT',
                            'AV',
                            'BA',
                            'BG',
                            'BI',
                            'BL',
                            'BN',
                            'BO',
                            'BR',
                            'BS',
                            'BT',
                            'BZ',
                            'CA',
                            'CB',
                            'CE',
                            'CH',
                            'CL',
                            'CN',
                            'CO',
                            'CR',
                            'CS',
                            'CT',
                            'CZ',
                            'EE',
                            'EN',
                            'FC',
                            'FE',
                            'FG',
                            'FI',
                            'FM',
                            'FR',
                            'GE',
                            'GO',
                            'GR',
                            'IM',
                            'IS',
                            'KR',
                            'LC',
                            'LE',
                            'LI',
                            'LO',
                            'LT',
                            'LU',
                            'MB',
                            'MC',
                            'ME',
                            'MI',
                            'MN',
                            'MO',
                            'MS',
                            'MT',
                            'NA',
                            'NO',
                            'NU',
                            'OR',
                            'PA',
                            'PC',
                            'PD',
                            'PE',
                            'PG',
                            'PI',
                            'PN',
                            'PO',
                            'PR',
                            'PT',
                            'PU',
                            'PV',
                            'PZ',
                            'RA',
                            'RC',
                            'RE',
                            'RG',
                            'RI',
                            'RM',
                            'RN',
                            'RO',
                            'SA',
                            'SI',
                            'SO',
                            'SP',
                            'SR',
                            'SS',
                            'SU',
                            'SV',
                            'TA',
                            'TE',
                            'TN',
                            'TO',
                            'TP',
                            'TR',
                            'TS',
                            'TV',
                            'UD',
                            'VA',
                            'VB',
                            'VC',
                            'VE',
                            'VI',
                            'VR',
                            'VT',
                            'VV'
                        ],
                        required: true,
                        styleClasses: 'col-md-6 mb-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },

                    {
                        model: 'checkDomicilio',
                        label: 'Di essere in possesso di domicilio differente dalla propria residenza:',
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        required: true,
                        styleClasses: 'col-md-12 my-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'statoDomicilio',
                        label: 'Di essere domiciliato nel seguente Stato:',
                        type: 'select',
                        values: [
                            'Afghanistan',
                            'Albania',
                            'Algeria',
                            'Andorra',
                            'Angola',
                            'Anguilla',
                            'Antartide',
                            'Antigua e Barbuda',
                            'Arabia Saudita',
                            'Argentina',
                            'Armenia',
                            'Aruba',
                            'Australia',
                            'Austria',
                            'Azerbaigian',
                            'Bahamas',
                            'Bahrein',
                            'Bangladesh',
                            'Barbados',
                            'Belgio',
                            'Belize',
                            'Benin',
                            'Bermuda',
                            'Bhutan',
                            'Bielorussia',
                            'Birmania',
                            'Bolivia',
                            'Bosnia ed Erzegovina',
                            'Botswana',
                            'Brasile',
                            'Brunei',
                            'Bulgaria',
                            'Burkina Faso',
                            'Burundi',
                            'Cambogia',
                            'Camerun',
                            'Canada',
                            'Capo Verde',
                            'Ciad',
                            'Cile',
                            'Cina',
                            'Cipro',
                            'Citt',
                            'Colombia',
                            'Comore',
                            'Corea del Nord',
                            'Corea del Sud',
                            "Costa d'Avorio",
                            'Costa Rica',
                            'Croazia',
                            'Cuba',
                            'Cura',
                            'Danimarca',
                            'Dominica',
                            'Ecuador',
                            'Egitto',
                            'El Salvador',
                            'Emirati Arabi Uniti',
                            'Eritrea',
                            'Estonia',
                            'Etiopia',
                            'Figi',
                            'Filippine',
                            'Finlandia',
                            'Francia',
                            'Gabon',
                            'Gambia',
                            'Georgia',
                            'Georgia del Sud e isole Sandwich meridionali',
                            'Germania',
                            'Ghana',
                            'Giamaica',
                            'Giappone',
                            'Gibilterra',
                            'Gibuti',
                            'Giordania',
                            'Grecia',
                            'Grenada',
                            'Groenlandia',
                            'Guadalupa',
                            'Guam',
                            'Guatemala',
                            'Guernsey',
                            'Guinea',
                            'Guinea-Bissau',
                            'Guinea Equatoriale',
                            'Guyana',
                            'Guyana francese',
                            'Haiti',
                            'Honduras',
                            'Hong Kong',
                            'India',
                            'Indonesia',
                            'Iran',
                            'Iraq',
                            'Irlanda',
                            'Islanda',
                            'Isola Bouvet',
                            'Isola di Man',
                            'Isola di Natale',
                            'Isola Norfolk',
                            'Isole ',
                            'Isole BES',
                            'Isole Cayman',
                            'Isole Cocos (Keeling)',
                            'Isole Cook',
                            'F',
                            'Isole Falkland',
                            'Isole Heard e McDonald',
                            'Isole Marianne Settentrionali',
                            'Isole Marshall',
                            'Isole minori esterne degli Stati Uniti',
                            'Isole Pitcairn',
                            'Isole Salomone',
                            'Isole Vergini britanniche',
                            'Isole Vergini americane',
                            'Israele',
                            'Italia',
                            'Jersey',
                            'Kazakistan',
                            'Kenya',
                            'Kirghizistan',
                            'Kiribati',
                            'Kuwait',
                            'Laos',
                            'Lesotho',
                            'Lettonia',
                            'Libano',
                            'Liberia',
                            'Libia',
                            'Liechtenstein',
                            'Lituania',
                            'Lussemburgo',
                            'Macao',
                            'Macedonia',
                            'Madagascar',
                            'Malawi',
                            'Malesia',
                            'Maldive',
                            'Mali',
                            'Malta',
                            'Marocco',
                            'Martinica',
                            'Mauritania',
                            'Mauritius',
                            'Mayotte',
                            'Messico',
                            'Micronesia',
                            'Moldavia',
                            'Mongolia',
                            'Montenegro',
                            'Montserrat',
                            'Mozambico',
                            'Namibia',
                            'Nauru',
                            'Nepal',
                            'Nicaragua',
                            'Niger',
                            'Nigeria',
                            'Niue',
                            'Norvegia',
                            'Nuova Caledonia',
                            'Nuova Zelanda',
                            'Oman',
                            'Paesi Bassi',
                            'Pakistan',
                            'Palau',
                            'Palestina',
                            'Panam',
                            'Papua Nuova Guinea',
                            'Paraguay',
                            'Per',
                            'Polinesia Francese',
                            'Polonia',
                            'Porto Rico',
                            'Portogallo',
                            'Monaco',
                            'Qatar',
                            'Regno Unito',
                            'RD del Congo',
                            'Rep. Ceca',
                            'Rep. Centrafricana',
                            'Rep. del Congo',
                            'Rep. Dominicana',
                            'Riunione',
                            'Romania',
                            'Ruanda',
                            'Russia',
                            'Sahara Occidentale',
                            'Saint Kitts e Nevis',
                            'Santa Lucia',
                            "Sant'Elena, Ascensione e Tristan da Cunha",
                            'Saint Vincent e Grenadine',
                            'Saint-Barth',
                            'Saint-Martin',
                            'Saint-Pierre e Miquelon',
                            'Samoa',
                            'Samoa Americane',
                            'San Marino',
                            'S',
                            'Senegal',
                            'Serbia',
                            'Seychelles',
                            'Sierra Leone',
                            'Singapore',
                            'Sint Maarten',
                            'Siria',
                            'Slovacchia',
                            'Slovenia',
                            'Somalia',
                            'Spagna',
                            'Sri Lanka',
                            'Stati Uniti',
                            'Sudafrica',
                            'Sudan',
                            'Sudan del Sud',
                            'Suriname',
                            'Svalbard e Jan Mayen',
                            'Svezia',
                            'Svizzera',
                            'Swaziland',
                            'Taiwan',
                            'Tagikistan',
                            'Tanzania',
                            'Terre australi e antartiche francesi',
                            "Territorio britannico dell'oceano Indiano",
                            'Thailandia',
                            'Timor Est',
                            'Togo',
                            'Tokelau',
                            'Tonga',
                            'Trinidad e Tobago',
                            'Tunisia',
                            'Turchia',
                            'Turkmenistan',
                            'Turks e Caicos',
                            'Tuvalu',
                            'Ucraina',
                            'Uganda',
                            'Ungheria',
                            'Uruguay',
                            'Uzbekistan',
                            'Vanuatu',
                            'Venezuela',
                            'Vietnam',
                            'Wallis e Futuna',
                            'Yemen',
                            'Zambia',
                            'Zimbabwe'
                        ],
                        required: true,
                        styleClasses: 'col-md-12 my-4 hidden',
                        validator: [
                            (value, field, model) => {
                                if (model['checkDomicilio'] == 'Dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'indirizzoDomicilio',
                        label: 'Indirizzo domicilio',
                        type: 'input',
                        inputType: 'text',
                        required: false,
                        styleClasses: 'col-md-12 mb-4 hidden',
                        validator: [
                            (value, field, model) => {
                                if (model['checkDomicilio'] == 'Dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'comuneDomicilio',
                        label: 'Comune domicilio',
                        type: 'input',
                        inputType: 'text',
                        required: false,
                        styleClasses: 'col-md-6 mb-4 hidden',
                        validator: [
                            (value, field, model) => {
                                if (model['checkDomicilio'] == 'Dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },

                    {
                        model: 'provinciaDomicilio',
                        label: 'provincia domicilio',
                        hint: "In caso di domicilio all'estero selezionare come provincia la sigla EE",
                        type: 'select',
                        values: [
                            'AG',
                            'AL',
                            'AN',
                            'AO',
                            'AP',
                            'AQ',
                            'AR',
                            'AT',
                            'AV',
                            'BA',
                            'BG',
                            'BI',
                            'BL',
                            'BN',
                            'BO',
                            'BR',
                            'BS',
                            'BT',
                            'BZ',
                            'CA',
                            'CB',
                            'CE',
                            'CH',
                            'CL',
                            'CN',
                            'CO',
                            'CR',
                            'CS',
                            'CT',
                            'CZ',
                            'EE',
                            'EN',
                            'FC',
                            'FE',
                            'FG',
                            'FI',
                            'FM',
                            'FR',
                            'GE',
                            'GO',
                            'GR',
                            'IM',
                            'IS',
                            'KR',
                            'LC',
                            'LE',
                            'LI',
                            'LO',
                            'LT',
                            'LU',
                            'MB',
                            'MC',
                            'ME',
                            'MI',
                            'MN',
                            'MO',
                            'MS',
                            'MT',
                            'NA',
                            'NO',
                            'NU',
                            'OR',
                            'PA',
                            'PC',
                            'PD',
                            'PE',
                            'PG',
                            'PI',
                            'PN',
                            'PO',
                            'PR',
                            'PT',
                            'PU',
                            'PV',
                            'PZ',
                            'RA',
                            'RC',
                            'RE',
                            'RG',
                            'RI',
                            'RM',
                            'RN',
                            'RO',
                            'SA',
                            'SI',
                            'SO',
                            'SP',
                            'SR',
                            'SS',
                            'SU',
                            'SV',
                            'TA',
                            'TE',
                            'TN',
                            'TO',
                            'TP',
                            'TR',
                            'TS',
                            'TV',
                            'UD',
                            'VA',
                            'VB',
                            'VC',
                            'VE',
                            'VI',
                            'VR',
                            'VT',
                            'VV'
                        ],
                        required: false,
                        styleClasses: 'col-md-6 mb-4 hidden',
                        validator: [
                            (value, field, model) => {
                                if (model['checkDomicilio'] == 'Dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'email',
                        label: 'Email personale',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-6 mt-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            }),
                            VueFormGenerator.validators.email.locale({
                                invalidEmail: '<span style="color:#ec250d;">Formato email non corretto</span>'
                            })
                        ]
                    },
                    {
                        model: 'repeatEmail',
                        label: 'Ridigitare email',
                        type: 'input',
                        inputType: 'text',

                        styleClasses: 'col-md-6 mt-4',
                        validator: [
                            (value, field, model) => {
                                return model.email == value ?
                                    [] :
                                    ['<span style="color:#ec250d;">Ripetere correttamente l\'email</span>'];
                            }
                        ]
                    },
                    {
                        model: 'pec',
                        label: 'Pec personale',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-6  mb-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            }),
                            VueFormGenerator.validators.email.locale({
                                invalidEmail: '<span style="color:#ec250d;">Formato email non corretto</span>'
                            })
                        ]
                    },
                    {
                        model: 'repeatPec',
                        label: 'Ridigitare pec',
                        type: 'input',
                        inputType: 'text',

                        styleClasses: 'col-md-6 mb-4',
                        validator: [
                            (value, field, model) => {
                                return model.pec == value ?
                                    [] :
                                    ['<span style="color:#ec250d;">Ripetere correttamente la pec</span>'];
                            }
                        ]
                    },

                    {
                        model: 'phone',
                        label: 'Numero di telefono',
                        type: 'input',
                        inputType: 'text',
                        required: true,
                        styleClasses: 'col-md-6 mb-4',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },

                    {
                        model: 'riserva',
                        label: 'Ai fini della riserva prevista dal bando, di essere dipendente di una pubblica amministrazione di cui all’articolo 1, comma 2 del decreto legislativo 30 marzo 2001, n. 165, di essere in possesso di uno dei titoli di studio previsti per l’accesso alla carriera di segretario comunale e provinciale e di avere un’anzianità di servizio di almeno cinque anni in posizioni funzionali per l’accesso alle quali è previsto il possesso dei medesimi titoli di studio',
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro', 'Non dichiaro'],
                        styleClasses: 'col-md-12  mt-4',
                        required: false,
                        styleClasses: 'col-md-12 pt15',
                        validator: [
                            VueFormGenerator.validators.string.locale({
                                fieldIsRequired: '<span style="color:#ec250d;">Campo obbligatorio</span>'
                            })
                        ]
                    },
                    {
                        model: 'enteRiserva',
                        label: 'Denominazione pubblica amministrazione o pubbliche amministrazioni',
                        type: 'textArea',
                        hint: 'se l’anzianità di servizio di almeno 5 anni richiesta dal bando è maturata presso amministrazioni pubbliche diverse',
                        styleClasses: 'col-md-12 hidden',
                        rows: 4,
                        required: false,
                        validator: [
                            (value, field, model) => {
                                if (model['riserva'] == 'Dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'posizioneRiserva',
                        label: 'Posizione funzionale occupata',
                        type: 'textArea',
                        hint: 'indicare comparto - categoria – profilo professionale',
                        styleClasses: 'col-md-6 mb-4 hidden',
                        rows: 2,
                        required: false,
                        validator: [
                            (value, field, model) => {
                                if (model['riserva'] == 'Dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'anniRiserva',
                        label: 'anni di effettivo servizio svolti in tale posizione',
                        type: 'input',
                        inputType: 'number',
                        styleClasses: 'col-md-6 mb-4 hidden',
                        rows: 2,
                        required: false,
                        hint: "Inserire solo numero - minimo 5 anni - vanno sottratti i provvedimenti  interruttivi  del  computo  dell'effettivo servizio ",
                        validator: [
                            (value, field, model) => {
                                if ((value == '' || isNaN(value)) && model['riserva'] == 'Dichiaro') {
                                    return ['<span style="color:#ec250d;">Campo obbligarorio</span>'];
                                } else if ((value < 5 || isNaN(value)) && model['riserva'] == 'Dichiaro') {
                                    return ['<span style="color:#ec250d;">Il requisito minimo è 5 anni</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    },
                    {
                        model: 'disabilita80',
                        label: "Di essere affetto da invalidità uguale o superiore all'80% e di essere esonerato dalla prova preselettiva",
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro', 'Non dichiaro'],
                        styleClasses: 'col-md-12 mb-4 mt-4',
                        required: true,
                        validator: [
                            (value, field) => {
                                return field.accepted.includes(value) ?
                                    [
                                        /* '<span style="color:#3C9891;">Campo corretto</span>' */
                                    ] :
                                    ['<span style="color:#ec250d;">Dichiarazione obbligatoria</span>'];
                            }
                        ]
                    },
                    {
                        model: 'disabilita',
                        label: "Di avere necessità di ausilio e/o tempi aggiuntivi per l'espletamento delle prove di esame",
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro', 'Non dichiaro'],
                        styleClasses: 'col-md-12 mb-4 mt-4',
                        required: true,
                        validator: [
                            (value, field) => {
                                return field.accepted.includes(value) ?
                                    [
                                        /* '<span style="color:#3C9891;">Campo corretto</span>' */
                                    ] :
                                    ['<span style="color:#ec250d;">Dichiarazione obbligatoria</span>'];
                            }
                        ]
                    },
                    {
                        model: 'ausilioTa',
                        label: 'Ausili e/o tempi aggiuntivi richiesti',
                        type: 'textArea',
                        styleClasses: 'col-md-12 mb-4 hidden',
                        rows: 4,
                        required: false,
                        validator: [
                            (value, field, model) => {
                                if (model['disabilita'] == 'Dichiaro' && value == '') {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ],
                        hint: 'Campo obbligatorio in caso di necessità di ausili e/o tempi aggiuntivi;'
                    },

                    {
                        model: 'consensoDisabilita',
                        label: "Di autorizzare il Ministero dell'Interno - Dipartimento per gli affari interni e territoriali - Albo Nazionale dei segretari comunali e provinciali, al trattamento dei dati sensibili per le finalità di cui al presente bando, ai sensi del decreto legislativo 30 Giugno 2003, n.196, del regolamento europeo n. 2016/679 e del relativo decreto legislativo di adeguamento n.101 del 10 Agosto 2018",
                        type: 'radios',
                        values: ['Dichiaro', 'Non dichiaro'],
                        accepted: ['Dichiaro'],
                        required: true,
                        styleClasses: 'col-md-12 mt-4',
                        validator: [
                            (value, field, model) => {
                                if (
                                    (model['disabilita'] == 'Dichiaro' ||
                                        model['disabilita80'] == 'Dichiaro') &&
                                    !field.accepted.includes(value)
                                ) {
                                    return ['<span style="color:#ec250d;">Dichiarazione obbligaroria</span>'];
                                } else {
                                    return [];
                                }
                            }
                        ]
                    }
                    ]
                }
            }],
            subscriptions: [],
            province: {
                AG: 'Agrigento',
                AL: 'Alessandria',
                AN: 'Ancona',
                AO: 'Aosta',
                AR: 'Arezzo',
                AP: 'Ascoli Piceno',
                AT: 'Asti',
                AV: 'Avellino',
                BA: 'Bari',
                BT: 'Barletta-Andria-Trani',
                BL: 'Belluno',
                BN: 'Benevento',
                BG: 'Bergamo',
                BI: 'Biella',
                BO: 'Bologna',
                BZ: 'Bolzano',
                BS: 'Brescia',
                BR: 'Brindisi',
                CA: 'Cagliari',
                CL: 'Caltanissetta',
                CB: 'Campobasso',
                CI: 'Carbonia-Iglesias',
                CE: 'Caserta',
                CT: 'Catania',
                CZ: 'Catanzaro',
                CH: 'Chieti',
                CO: 'Como',
                CS: 'Cosenza',
                CR: 'Cremona',
                KR: 'Crotone',
                CN: 'Cuneo',
                EN: 'Enna',
                FM: 'Fermo',
                FE: 'Ferrara',
                FI: 'Firenze',
                FG: 'Foggia',
                FC: 'Forlì-Cesena',
                FR: 'Frosinone',
                GE: 'Genova',
                GO: 'Gorizia',
                GR: 'Grosseto',
                IM: 'Imperia',
                IS: 'Isernia',
                SP: 'La Spezia',
                AQ: "L'Aquila",
                LT: 'Latina',
                LE: 'Lecce',
                LC: 'Lecco',
                LI: 'Livorno',
                LO: 'Lodi',
                LU: 'Lucca',
                MC: 'Macerata',
                MN: 'Mantova',
                MS: 'Massa-Carrara',
                MT: 'Matera',
                ME: 'Messina',
                MI: 'Milano',
                MO: 'Modena',
                MB: 'Monza e della Brianza',
                NA: 'Napoli',
                NO: 'Novara',
                NU: 'Nuoro',
                OT: 'Olbia-Tempio',
                OR: 'Oristano',
                PD: 'Padova',
                PA: 'Palermo',
                PR: 'Parma',
                PV: 'Pavia',
                PG: 'Perugia',
                PU: 'Pesaro e Urbino',
                PE: 'Pescara',
                PC: 'Piacenza',
                PI: 'Pisa',
                PT: 'Pistoia',
                PN: 'Pordenone',
                PZ: 'Potenza',
                PO: 'Prato',
                RG: 'Ragusa',
                RA: 'Ravenna',
                RC: 'Reggio Calabria',
                RE: 'Reggio Emilia',
                RI: 'Rieti',
                RN: 'Rimini',
                RM: 'Roma',
                RO: 'Rovigo',
                SA: 'Salerno',
                VS: 'Medio Campidano',
                SS: 'Sassari',
                SV: 'Savona',
                SI: 'Siena',
                SR: 'Siracusa',
                SO: 'Sondrio',
                SU: 'Sud Sardegna',
                TA: 'Taranto',
                TE: 'Teramo',
                TR: 'Terni',
                TO: 'Torino',
                OG: 'Ogliastra',
                TP: 'Trapani',
                TN: 'Trento',
                TV: 'Treviso',
                TS: 'Trieste',
                UD: 'Udine',
                VA: 'Varese',
                VE: 'Venezia',
                VB: 'Verbano-Cusio-Ossola',
                VC: 'Vercelli',
                VR: 'Verona',
                VV: 'Vibo Valentia',
                VI: 'Vicenza',
                VT: 'Viterbo'
            }
        };
    },
    mutations: {
        services(state, services) {
            state.services = services;
        },
        updateDataForm(state, updateDataForm) {
            state.updateDataForm = updateDataForm;
        },
        shownType(state, shownType) {
            state.shownType = shownType;
        },
        requiredUpload(state, required) {
            state.requiredUpload = required;
        },
        service(state, service) {
            axios.defaults.baseURL = state.api + '/' + service.slug;
            state.service = service;
        },
        comunications(state, comunications) {
            state.comunications = comunications;
        },
        apps(state, apps) {
            state.apps = apps;
        },
        app(state, app) {
            state.app = app;
        },
        userApp(state, userApp) {
            state.userApp = userApp;
        },
        subscriptions(state, subscription) {
            state.subscriptions.push(subscription);
        },
        notification(state, notification) {
            state.notification = notification;
        },
        authed(state, authed) {
            state.authed = authed;
        },
        addService(state, service) {
            state.services.push(service);
        },
        user(state, user) {
            state.user = user;
        },
        auth(state, auth) {
            state.auth = auth;
        },
        users(state, users) {
            state.users = users;
        }
    },
    actions: {
        getAllServices({ commit, dispatch, state }, params) {
            if (params[0]) {
                state.spidTicket = params[0];
            }

            return new Promise(async (resolve, reject) => {
                let prod = true;
                state.api = prod ? 'https://app.scanshareservice.it/api/v1/service' : 'http://localhost:3000/api/v1/service';

                axios.defaults.baseURL = state.api;
                const response = await async(axios.get('/'));
                const services = response.data.services;
                let comunications = response.data.comunications ?? [];
                const service = services.find(service => service.slug == params[1]);

                comunications = response.data.comunications.filter(comunication => comunication.codice == params[2]);
                if (!service) {
                    router.replace({ name: 'not-found', params: { service: 'service', app: 'app' } });
                    resolve();
                } else {
                    const apps = JSON.parse(service.apps);
                    service.apps = apps;
                    service.apps[service.apps.findIndex(app => app.code == 'coa2021')] =
                        state.apps[state.apps.findIndex(app => app.code == 'coa2021')];
                    commit('services', services);
                    commit('service', service);
                    commit('comunications', comunications);
                    if (params[2] == 'app') {
                        router.replace({ name: 'not-found', params: { service: 'service', app: 'app' } });
                    } else {
                        const app = service.apps.find(app => app.code == params[2]);
                        if (app) {
                            commit('app', app);
                        }
                    }

                    resolve();
                }
            });
        },

        refreshPage({ commit, dispatch, state }, auth) {
            router.go();
        },

        adminSignin({ commit, dispatch, state }, auth) {
            return new Promise((resolve, reject) => {
                auth.service = state.service.slug;
                auth.accessMethod = 'credenziali';
                auth.app = state.app.code;
                axios
                    .post(`/adminSignin`, { auth })
                    .then(res => {
                        if (res.data.status == 'success') {
                            Vue.$cookies.set('jwt', res.data.data.access_token);
                            Vue.$cookies.set('role', res.data.data.role);
                            Vue.$cookies.set('type', res.data.data.type);
                            Vue.$cookies.set('app', res.data.data.app);

                            const user = {};
                            user.accessToken = res.data.data.access_token;
                            user.type = res.data.data.role;
                            user.nome = res.data.data.nome;
                            user.cognome = res.data.data.cognome;
                            user.data = res.data.data.data;
                            user.app = res.data.data.app;

                            if (res.data.data.types) {
                                let userApp = JSON.parse(res.data.data.types.data);
                                userApp.status = res.data.data.types.status;
                                commit('userApp', userApp);
                            }
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.access_token;
                            commit('user', user);
                            commit('notification', { message: 'Login riuscito!', type: 'primary' });
                            router.replace({ name: 'app', params: { app: state.app.code } });
                        }
                        if (res.data.status == 400) {
                            commit('notification', { message: res.data.message, type: 'warning' });
                        }
                        resolve();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
        signIn({ commit, dispatch, state }, auth) {
            return new Promise((resolve, reject) => {
                auth.service = state.service.slug;
                auth.app = state.app.code;
                auth.type = 'credenziali';

                axios
                    .post(`/signin`, { auth })
                    .then(res => {
                        if (res.data.status == 'success') {
                            Vue.$cookies.set('jwt', res.data.data.access_token);
                            Vue.$cookies.set('role', res.data.data.role);
                            Vue.$cookies.set('type', res.data.data.type);
                            Vue.$cookies.set('app', res.data.data.app);

                            const user = {};

                            user.accessToken = res.data.data.access_token;
                            user.type = res.data.data.role;
                            user.nome = res.data.data.nome;
                            user.cognome = res.data.data.cognome;
                            user.data = res.data.data.data;
                            user.app = res.data.data.app;

                            if (res.data.data.types) {
                                let userApp = JSON.parse(res.data.data.types.data);
                                userApp.status = res.data.data.types.status;

                                commit('userApp', userApp);
                            }

                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.access_token;
                            commit('user', user);
                            commit('notification', { message: 'Login riuscito!', type: 'primary' });
                            router.push({ name: 'app', params: { app: state.app.code } });
                        }
                        if (res.data.status == 400) {
                            commit('notification', { message: res.data.message, type: 'warning' });
                        }
                        resolve();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
        tryAutoLogin({ commit, dispatch, state }) {
            if (this.state.app.code) {
                if (!state.app.access) {
                    dispatch('logout');
                }
            }
            return new Promise(async (resolve, reject) => {
                try {
                    const jwt = window.$cookies.get('jwt');
                    const type = window.$cookies.get('type');

                    if (!jwt) {
                        resolve();
                    } else {
                        // this.token = this.$route.query.code;

                        let auth = {};
                        auth.token = jwt;
                        auth.type = type;
                        auth.app = state.app.code;

                        const res = await async(
                            axios.post(`/signin`, {
                                auth
                            })
                        );

                        if (res.data.status == 'error') {
                            resolve();
                        } else {
                            if (res.data.data.access_token) {
                                Vue.$cookies.set('jwt', res.data.data.access_token);
                                Vue.$cookies.set('type', res.data.data.type);
                                Vue.$cookies.set('app', res.data.data.app);

                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.access_token;
                                axios.defaults.headers.common['accessMethod'] = state.app.accessMethod;
                                const user = {};
                                user.spidCode = res.data.data.spidCode;
                                user.uploadTime = res.data.data.uploadTime;
                                user.uploadTimeAcceptance = res.data.data.uploadTimeAcceptance;
                                user.type = res.data.data.role;
                                user.accessToken = res.data.data.access_token;
                                user.app = res.data.data.app;
                                user.data = res.data.data.data;
                                commit('user', user);
                                if (this.state.app.code != 'coa2021') {
                                    if (res.data.data.types) {
                                        const data = {};
                                        data.pec = res.data.data.types.pec;
                                        commit('updateDataForm', data);
                                        let userApp = JSON.parse(res.data.data.types.data);
                                        userApp.status = res.data.data.types.status;
                                        commit('userApp', userApp);
                                    }
                                    commit('notification', { message: 'Login riuscito!', type: 'primary' });
                                } else {
                                    let types = res.data.data.types ? res.data.data.types : false;

                                    if (types) {
                                        let model = res.data.data.types.model;
                                        let dataModel = JSON.parse(res.data.data.types.data);
                                        if (model) {
                                            let data = JSON.parse(model).form;

                                            commit('updateDataForm', data);
                                            let userApp = JSON.parse(res.data.data.types.data);
                                            userApp.status = res.data.data.types.status;
                                            commit('userApp', userApp);
                                        } else {
                                            let data = {};
                                            data.pec = res.data.data.types.pec;
                                            data.repeatPec = res.data.data.types.pec;
                                            data.email = res.data.data.types.email;
                                            data.repeatEmail = res.data.data.types.email;
                                            data.statoDomicilio = dataModel.model.statoDomicilio;
                                            data.provinciaDomicilio = dataModel.model.provinciaDomicilio;
                                            data.comuneDomicilio = dataModel.model.comuneDomicilio;
                                            data.domicilio = dataModel.model.domicilio;
                                            data.statoResidenza = dataModel.model.statoResidenza;
                                            data.provinciaResidenza = dataModel.model.provinciaResidenza;
                                            data.comuneResidenza = dataModel.model['comune di residenza'];
                                            data.residenza = dataModel.model.indirizzoResidenza;

                                            commit('updateDataForm', data);
                                            let userApp = JSON.parse(res.data.data.types.data);
                                            userApp.status = res.data.data.types.status;
                                            commit('userApp', userApp);
                                        }
                                    }
                                }
                            }
                            resolve();
                        }
                    }
                    if (state.spidTicket) {
                        dispatch('sendRequestToken');
                    }
                } catch (error) { }
            });
        },
        logout({ commit, dispatch, state }, token) {
            return new Promise(async (resolve, reject) => {
                const res = await async(
                    axios.post(`/logout`, {
                        app: state.app.code
                    })
                );

                Vue.$cookies.remove('role');
                Vue.$cookies.remove('jwt');
                Vue.$cookies.remove('type');
                Vue.$cookies.remove('app');
                commit('user', false);

                commit('users', []);
                commit('notification', { message: 'Logout riuscito!', type: 'primary' });
                resolve();
            });
        },
        addService({ commit, dispatch }, service) {
            axios
                .post('/service', service)
                .then(() => {
                    dispatch('services');
                })
                .catch(error => console.log(error));
        },
        updateService({ commit, dispatch }, service) {
            axios
                .patch('/service', service)
                .then(() => {
                    dispatch('services');
                })
                .catch(error => console.log(error));
        },
        addUser({ commit, dispatch }, user) {
            const data = { user: null, slug: null };
            data.user = user;
            data.slug = this.getters.service.slug;
            axios
                .post('/user', data)
                .then(() => { })
                .catch(error => console.log(error));
        },

        getAllUsers({ commit, dispatch, state }, app) {
            const auth = {};
            auth.app = app;
            auth.token = state.user.access_token;

            return new Promise((resolve, reject) => {
                axios
                    .post(`/user/${app}`, { auth })
                    .then(res => {
                        if (app == 'coa2021') {
                            const users = res.data.users;
                            commit('users', users);
                            resolve();
                        } else {
                            let today = new Date();
                            let dd = String(today.getDate()).padStart(2, '0');
                            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                            let yyyy = today.getFullYear();
                            today = dd + '/' + mm + '/' + yyyy;

                            const users = res.data.users;
                            users.forEach(user => {
                                user.presenze = JSON.parse(user.data);
                                if (user.presenze) {
                                    user.presenzaNow = user.presenze.find(presenza => {
                                        return presenza.data == today;
                                    });
                                    if (user.presenzaNow) {
                                        user.presentNow = today;
                                        user.minuti = user.presenzaNow.minuti;
                                    } else {
                                        user.presentNow = false;
                                        user.presentNow = null;
                                    }
                                }
                            });
                            commit('users', users);
                            resolve();
                        }
                    })
                    .catch(error => console.log(error));
            });
        },
        getType({ commit, dispatch, state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/user/${state.app.code}/getType`, data)
                    .then(res => {
                        commit('shownType', res.data.dataOut);

                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },
        storeComunication({ commit, dispatch, state }, comunication) {
            let data = {
                'text': comunication.text,
                'subject': comunication.subject,
                'file': comunication.file,
            };


            return new Promise((resolve, reject) => {
                axios
                    .post(`/user/${state.app.code}/storeComunication/`, { data })
                    .then(res => {
                        if (res.data.status == 'success') {
                        }
                        resolve(res.data.createdComunication.id);
                    })
                    .catch(error => {
                        reject();
                    });
            });
        },
        downloadAttachment({ commit, dispatch, state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/user/${state.app.code}/downloadAttachment/${data}`, { responseType: 'blob' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = data + "_DOMANDA";
                        link.click();
                        URL.revokeObjectURL(link.href);
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },

        downloadRicevuta({ commit, dispatch, state }, data) {
            return new Promise(async (resolve, reject) => {
                const res = await async(
                    axios.post(
                        `/user/${state.app.code}/downloadRicevuta/`, {}, {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/pdf'
                        },
                        responseType: 'blob'
                    }
                    )
                );
                let blob = new Blob([res.data], { type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                link.download = 'Ricevuta_titoli.pdf';
                link.click();
            });
        },

        downloadRicevutaAccettazione({ commit, dispatch, state }, data) {
            return new Promise(async (resolve, reject) => {
                const res = await async(
                    axios.post(
                        `/user/${state.app.code}/downloadRicevutaAccettazione/`, {}, {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/pdf'
                        },
                        responseType: 'blob'
                    }
                    )
                );
                let blob = new Blob([res.data], { type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                link.download = 'Ricevuta_titoli.pdf';
                link.click();
            });
        },

        downloadTitoli({ commit, dispatch, state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/user/${state.app.code}/downloadTitoli/${data}`, { responseType: 'blob' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = data + "_TITOLI";
                        link.click();
                        URL.revokeObjectURL(link.href);
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },
        downloadAccettazione({ commit, dispatch, state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/user/${state.app.code}/downloadAccettazione/${data}`, { responseType: 'blob' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = data + "_ACCETTAZIONE";
                        link.click();
                        URL.revokeObjectURL(link.href);
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },

        downloadBancadati({ commit, dispatch, state }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/user/${state.app.code}/downloadBancadati/`, { responseType: 'blob' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'BancadatiCOA2021';
                        link.click();
                        URL.revokeObjectURL(link.href);
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },
        downloadAllegato({ commit, dispatch, state }, allegato) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/user/${state.app.code}/downloadAllegato/${allegato}`, { responseType: 'blob' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = allegato;
                        link.click();
                        URL.revokeObjectURL(link.href);
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },

        downloadAllegatoComunicazione({ commit, dispatch, state }, allegato) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/user/${state.app.code}/downloadAllegatoComunicazione/${allegato}`, { responseType: 'blob' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = allegato;
                        link.click();
                        URL.revokeObjectURL(link.href);
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },
        downloadDomandaManager({ commit, dispatch, state }, spid) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/user/${state.app.code}/scaricaDomandaFromManager`, { spid }, { responseType: 'blob' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = spid;
                        link.click();
                        URL.revokeObjectURL(link.href);
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },
        signUser({ commit, dispatch, state }, sendData) {
            sendData.serviceSlug = state.service.slug;
            return new Promise((resolve, reject) => {
                axios
                    .post(`/user/${sendData.app}/signUser`, sendData)
                    .then(() => {
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },

        scaricaAttestato({ commit, dispatch, state }, selectedDay) {
            return new Promise(async (resolve, reject) => {
                const res = await async(
                    axios.post(
                        `https://app.scanshareservice.it/api/v1/service/${state.service.slug}/user/${state.app.code}/scaricaAttestato`, {
                        selectedDay
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/pdf'
                        },
                        responseType: 'blob'
                    }
                    )
                );
                let blob = new Blob([res.data], { type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                link.download =
                    'Attestato_' +
                    state.user.cognome +
                    state.user.nome +
                    selectedDay.replace('/', '.').replace('/', '.') +
                    '.pdf';
                link.click();
            });
        },
        scaricaDomanda({ commit, dispatch, state }, app) {
            return new Promise(async (resolve, reject) => {
                const res = await async(
                    axios.post(
                        `/user/${state.app.code}/scaricaDomanda`, {
                        app
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/pdf'
                        },
                        responseType: 'blob'
                    }
                    )
                );
                let blob = new Blob([res.data], { type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                link.download = 'Domanda_.pdf';
                link.click();
            });
        },
        setPassword({ commit, dispatch, state }, auth) {
            if (auth.password != auth.repeatPassword) {
                commit('notification', { message: 'Le password non coincidono', type: 'warning' });
            } else {
                return new Promise((resolve, reject) => {
                    auth.service = state.service.slug;
                    axios
                        .patch(
                            `https://app.scanshareservice.it/api/v1/service/${state.service.slug}/user/${auth.app}/setPassword`, {
                            auth
                        }
                        )
                        .then(res => {
                            if (res.data.status == 'error') {
                                commit('notification', { message: res.data.message, type: 'warning' });
                            }
                            if (res.data.status == 'success') {
                                commit('notification', { message: res.data.message, type: 'primary' });
                                router.replace({ name: 'signin' });
                            }
                            resolve();
                        })
                        .catch(error => {
                            console.log(error);
                        });
                });
            }
        },
        recoveryPassword({ commit, dispatch, state }, auth) {
            if (auth.email != auth.repeatEmail) {
                commit('notification', { message: 'Le Email non coincidono', type: 'warning' });
            } else {
                return new Promise((resolve, reject) => {
                    auth.service = state.service.slug;
                    axios
                        .patch(
                            `https://app.scanshareservice.it/api/v1/service/${state.service.slug}/user/${auth.app}/recoveryPassword`, { auth }
                        )
                        .then(res => {
                            if (res.data.status == 'error') {
                                commit('notification', { message: res.data.message, type: 'warning' });
                            }
                            if (res.data.status == 'success') {
                                commit('notification', { message: res.data.message, type: 'primary' });
                                router.replace({ name: 'signin' });
                            }
                            resolve();
                        })
                        .catch(error => {
                            console.log(error);
                        });
                });
            }
        },
        sendEmail({ commit, dispatch, state }, user) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `https://app.scanshareservice.it/api/v1/service/${state.service.slug}/user/${user.app}/sendEmail`,
                        user
                    )
                    .then(res => {
                        /*  if (res.data.status == 'error') {
                        commit('notification', { message: res.data.message, type: 'warning' });
                    }
                    if (res.data.status == 'success') {
                        commit('notification', { message: res.data.message, type: 'primary' });
                    } */
                        resolve();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
        sendRequestToken({ commit, dispatch, state }) {
            return new Promise(async (resolve, reject) => {
                try {
                    let auth = {};
                    auth.spidTicket = state.spidTicket;
                    auth.spid = true;
                    const res = await async(
                        axios.post(`/signin`, {
                            auth
                        })
                    );
                    console.log(res);
                    if (res.data.data.access_token) {
                        Vue.$cookies.set('jwt', res.data.data.access_token);
                        Vue.$cookies.set('role', 'default');
                        Vue.$cookies.set('type', 'spid');
                        Vue.$cookies.set('app', res.data.data.app);

                        const user = {};
                        user.deb = res;
                        user.accessToken = res.data.data.access_token;
                        user.type = res.data.data.role;
                        user.app = res.data.data.app;

                        if (res.data.data.types) {
                            let userApp = JSON.parse(res.data.data.types.data);

                            userApp.status = res.data.data.types.status;
                            commit('userApp', userApp);
                        }

                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.access_token;
                        commit('user', user);
                        commit('notification', { message: 'Login riuscito!', type: 'primary' });

                        router.go({
                            name: 'app',
                            params: { service: 'albo-nazionale-segretari', app: state.app.code }
                        });

                        resolve();
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        },
        addMinutes({ commit, dispatch, state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `https://app.scanshareservice.it/api/v1/service/${state.service.slug}/user/${state.app.code}/addMinutes`, { data }
                    )
                    .then(res => {
                        /*         if (res.data.status == 'success') {} */
                        console.log(res.data);
                    })
                    .catch(error => { });
                resolve();
            });
        },
        settaPresenza({ commit, dispatch, state }, data) {
            console.log(data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `https://app.scanshareservice.it/api/v1/service/${state.service.slug}/user/${state.app.code}/settaPresenza`, { data }
                    )
                    .then(res => {
                        console.log(res.data);
                    })
                    .catch(error => { });
                resolve();
            });
        },

        savecoa2021({ commit, dispatch, state }, app) {
            let data = {};
            data.app = app;
            data.user = state.user;

            return new Promise((resolve, reject) => {
                axios
                    .post('/saveApp', { data })
                    .then(res => {
                        if (res.data.status == 'success') {
                            commit('notification', {
                                message: "Candidatura Salvata correttamente. E' ora possibile inviare la domanda",
                                type: 'info'
                            });
                            let savedApp = JSON.parse(res.data.updated.data);
                            savedApp.status = res.data.updated.status;

                            commit('app', savedApp);
                        }
                        if (res.data.status == 'error') {
                            commit('notification', {
                                message: "Candidatura Salvata correttamente. E' ora possibile inviare la domanda",
                                type: 'info'
                            });
                        }
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },

        inviaDomanda({ commit, dispatch, state }, app) {
            let data = {};
            data.app = app;
            data.user = state.user;
            return new Promise((resolve, reject) => {
                axios
                    .post('/inviaDomanda', { data })
                    .then(res => {
                        if (res.data.status == 'success') {
                            commit('notification', {
                                message: "Candidatura Inviata correttamente. E' possibile modificare la domanda fino alla chiusura del bando",
                                type: 'info'
                            });

                            let savedApp = JSON.parse(res.data.createdType.data);
                            savedApp.status = res.data.createdType.status;

                            commit('app', savedApp);
                            // setTimeout(3000, router.go());
                        }
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },

        generaDomanda({ commit, dispatch, state }, data) {
            data.user = state.user;

            return new Promise((resolve, reject) => {
                axios
                    .post('/generaDomanda', { data })
                    .then(res => { })
                    .catch(error => console.log(error));
                resolve();
            });
        },

        uploadFile({ commit, dispatch, state }, formData) {
            /*   let data = {};
            data.user = state.user;
            data.formData = formData; */
            return new Promise((resolve, reject) => {
                axios
                    .post('/uploadFile', formData, {
                        headers: {
                            enctype: 'multipart/form-data'
                        }
                    })
                    .then(function () {
                        console.log('SUCCESS!!');
                    })
                    .catch(function () {
                        console.log('FAILURE!!');
                    });
                resolve();
            });
        },
        uploadAllegatoComunicazione({ commit, dispatch, state }, formData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/uploadComunicationAttachment', { file: formData }, {
                        headers: {
                            enctype: 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        resolve(200);

                    })
                    .catch(function () {
                        resolve(200);
                    });
            });
        },
        aggiornaApp({ commit, dispatch, state }, data) {
            data.draftLesson.modulo = parseInt(data.draftLesson.modulo);
            data.draftLesson.duration = parseInt(data.draftLesson.duration);

            return new Promise((resolve, reject) => {
                axios
                    .post(`/aggiornaApp`, {
                        data
                    })
                    .then(res => {
                        console.log(res);

                        if (res.data.status == 'success') {
                            commit('notification', { message: "l'app è stata aggiornata", type: 'info' });
                        } else {
                            commit('notification', { message: "verificare l'esito del salvataggio", type: 'warning' });
                        }
                        resolve();
                    })
                    .catch(error => console.log(error));
            });
        },
        updateDataForm({ commit, dispatch, state }, form) {
            delete form.states;
            delete form.province;
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `https://app.scanshareservice.it/api/v1/service/${state.service.slug}/user/${state.app.code}/updateData`, { form }
                    )
                    .then(res => {
                        if (res.data.status == 'success') {
                            commit('notification', { message: 'Dati aggiornati', type: 'info' });
                        } else {
                            commit('notification', { message: 'Errore nel salvataggio', type: 'warning' });
                        }
                        resolve();
                        location.reload();
                    })
                    .catch(error => console.log(error));
            });
        }
    },
    getters: {
        services(state) {
            return state.services;
        },
        comunications(state) {
            return state.comunications.sort((a, b) => b.id - a.id);
        },
        updateDataForm(state) {
            return state.updateDataForm;
        },
        service(state) {
            return state.service;
        },
        apps(state) {
            return state.apps;
        },
        userApp(state) {
            return state.userApp;
        },
        app(state) {
            return state.app;
        },
        slugs(state) {
            return state.services.map(service => service.slug);
        },
        serviceUrl(state) {
            return state.service;
        },
        user(state) {
            return state.user;
        },
        users(state) {
            return state.users;
        },
        notification(state) {
            return state.notification;
        },
        authed(state) {
            return state.authed;
        },
        requiredUpload(state) {
            return state.requiredUpload;
        },
        shownType(state) {
            return state.shownType;
        }
    }
});

export default store;