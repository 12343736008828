import Vuex from 'vuex';

let App = {};
App.action = {
    getAllUsers({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/user')
                .then(res => {
                    const users = res.data.users;
                    commit('users', users);
                    resolve();
                })
                .catch(error => console.log(error));
        });
    }
};

export default App;