<template>
	<div class="wrapper">
		<side-bar>
			<template v-slot:links>
				<!-- v-if="user.type == 'manager' || user.type == 'admin'" -->
				<!-- 				<sidebar-link to="admin" name="admin" icon="tim-icons icon-chart-pie-36" />
 -->
				<sidebar-link
					v-if="user.type == 'admin' && dashboard"
					:to="`/${slug}/dashboard`"
					:name="dashboardName"
					icon="tim-icons icon-chart-pie-36"
				/>
				<sidebar-link :to="`/${slug}`" name="Home" icon="tim-icons icon-chart-pie-36" />

				<!-- <sidebar-link :to="`/${slug}/assistenza`" name="Assistenza" icon="tim-icons icon-chart-pie-36" />
 -->
				<sidebar-link
					v-if="dashboard"
					:to="`/${slug}/privacy`"
					name="Privacy"
					icon="tim-icons icon-chart-pie-36"
				/>
				<sidebar-link
					v-if="app.code == 'coa2021'"
					:to="`/${slug}/faq`"
					name="Assistenza e faq"
					icon="tim-icons icon-chart-pie-36"
				/>
			</template>
		</side-bar>
		<div class="main-panel">
			<top-navbar></top-navbar>

			<dashboard-content @click.native="toggleSidebar"> </dashboard-content>

			<content-footer></content-footer>
		</div>
	</div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from './SampleNavbar.vue';
import ContentFooter from './SampleFooter.vue';
import DashboardContent from './Content.vue';
import MobileMenu from './MobileMenu';
import router from '../../router';
export default {
	components: {
		TopNavbar,
		ContentFooter,
		DashboardContent,
		MobileMenu
	},
	mounted() {
		this.i18n = this.$i18n;
		/* 		if (this.enableRTL) {
			this.i18n.locale = 'ar';
			this.$rtl.enableRTL();
		}
		this.initBigChart(0); */
	},
	computed: {
		enableRTL() {
			return this.$route.query.enableRTL;
		},
		isRTL() {
			return this.$rtl.isRTL;
		},
		bigLineChartCategories() {
			return this.$t('dashboard.chartCategories');
		},
		user() {
			return this.$store.getters.user;
		},
		slug() {
			return this.$router.currentRoute.params.app
				? this.$store.getters.service.slug + '/' + this.$router.currentRoute.params.app
				: this.$store.getters.service.slug;
		},
		dashboard() {
			return router.currentRoute.name == 'home' ? false : true;
		},
		dashboardName() {
			return router.currentRoute.name == 'home' ? false : 'dashboard';
		},
		app() {
			return this.$store.getters.app;
		}
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		}
	},
	created() {}
};
</script>
