import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import VueCookies from 'vue-cookies';
import Stepper from 'vue-good-wizard';
import VueFormGenerator from 'vue-form-generator';
import 'vue-form-generator/dist/vfg.css';

import JsonEditor from 'vue-json-edit';

import App from './App';
import store from '@/store/';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import router from './router/';
import BlackDashboard from './plugins/blackDashboard';
import i18n from './i18n';
import './registerServiceWorker';
import ToggleButton from 'vue-js-toggle-button';
// import into project

import VuejsDialog from 'vuejs-dialog';

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

// Tell Vue to install the plugin.
Vue.use(VuejsDialog, {
    html: true,
    loader: false,
    okText: 'Conferma',
    cancelText: 'Annulla',
    animation: 'bounce'
});
Vue.use(JsonEditor);
Vue.use(Stepper);
Vue.use(VueCookies);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(ToggleButton);
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueFormGenerator);

const currentUrl = window.location.pathname;
const params = currentUrl.split('/');
params[1] == '' ? (params[1] = 'service') : params[1];
params[2] == '' ? (params[2] = 'app') : params[2];

const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get('code');
params[0] = code;
store
    .dispatch('getAllServices', params)
    .then(() => {
        store
            .dispatch('tryAutoLogin')
            .then(() => {
                new Vue({
                    router,
                    i18n,
                    store,
                    render: h => h(App)
                }).$mount('#app');
            })
            .catch(error => console.log(error));
    })
    .catch(error => console.log(error));

/* eslint-disable no-new */