<template>
	<nav
		class="navbar navbar-expand-lg navbar-absolute"
		:class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
	>
		<div class="container-fluid">
			<div class="navbar-wrapper">
				<div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
					<button type="button" class="navbar-toggler" @click="toggleSidebar">
						<span class="navbar-toggler-bar bar1"></span>
						<span class="navbar-toggler-bar bar2"></span>
						<span class="navbar-toggler-bar bar3"></span>
					</button>
				</div>
				<!-- <a class="navbar-brand" href="#pablo">{{ routeName }}</a> -->
			</div>
			<button
				class="navbar-toggler"
				type="button"
				@click="toggleMenu"
				data-toggle="collapse"
				data-target="#navigation"
				aria-controls="navigation-index"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-bar navbar-kebab"></span>
				<span class="navbar-toggler-bar navbar-kebab"></span>
				<span class="navbar-toggler-bar navbar-kebab"></span>
			</button>

			<collapse-transition>
				<div class="collapse navbar-collapse show" v-show="showMenu">
					<ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
						<!-- <div class="search-bar input-group" @click="searchModalVisible = true">
							<button
								class="btn btn-link"
								id="search-button"
								data-toggle="modal"
								data-target="#searchModal"
							>
								<i class="tim-icons icon-zoom-split"></i>
							</button>
						</div>
						<modal
							:show.sync="searchModalVisible"
							class="modal-search"
							id="searchModal"
							:centered="false"
							:show-close="true"
						>
							<input
								slot="header"
								v-model="searchQuery"
								type="text"
								class="form-control"
								id="inlineFormInputGroup"
								placeholder="SEARCH"
							/>
						</modal> -->
						<base-dropdown
							tag="li"
							:menu-on-right="!$rtl.isRTL"
							title-tag="a"
							class="nav-item"
							menu-classes="dropdown-navbar"
						>
							<a
								slot="title"
								href="#"
								class="dropdown-toggle nav-link"
								data-toggle="dropdown"
								aria-expanded="true"
							>
								<i class="tim-icons icon-single-02"></i>

								<b class="caret d-none d-lg-block d-xl-block"></b>
								<p class="d-lg-none">
									Log out
								</p>
							</a>

							<!-- 					<li class="nav-link">
								<a href="#" class="nav-item dropdown-item">Settings</a>
							</li> -->
							<div class="dropdown-divider"></div>
							<li class="nav-link" @click="log">
								<a class="nav-item dropdown-item">{{ user ? 'logout' : 'login' }}</a>
							</li>
						</base-dropdown>
					</ul>
				</div>
			</collapse-transition>
		</div>
	</nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import Modal from '@/components/Modal';
import router from './../../router';
export default {
	components: {
		CollapseTransition,
		Modal
	},
	created() {},
	computed: {
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
		isRTL() {
			return this.$rtl.isRTL;
		},
		service() {
			return this.$store.getters.service;
		},
		user() {
			return this.$store.getters.user;
		},
		routeName() {
			return this.$router.currentRoute.name;
		}
	},
	data() {
		return {
			activeNotifications: false,
			showMenu: false,
			searchModalVisible: false,
			searchQuery: ''
		};
	},
	methods: {
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		toggleMenu() {
			this.showMenu = !this.showMenu;
		},

		async log() {
			if (this.user) {
				await this.$store.dispatch('logout');

				if (this.routeName == 'userProfile') {
					this.$router.replace({ path: '/home' });
				} else {
					this.$router.go();
				}
			} else {
				if (!(this.routeName == 'signin')) {
					this.$router.push({
						name: 'signin',
						params: { service: this.$store.getters.service.slug, app: this.$store.getters.app.code }
					});
				}
			}
		}
	}
};
</script>
<style></style>
