<template>
	<div>
		<card>
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</card>

		<card>
			<div>
				<h4 class="card-title text-danger">Errore 401 - Non autorizzato</h4>
				<p class="card-text title">Non sei autorizzato a visualizzare la pagina richiesta.</p>
			</div>
		</card>
	</div>
</template>

<script>
import logo from './../assets/img/albo-nazionale-segretari.svg';
export default {
	data() {
		return { logo };
	}
};
</script>

<style scoped>
ul {
	/* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}
li {
	/* Remove bullets */
	padding: 3px; /* Remove padding */
	margin: 0; /* Remove margins */
}

.page {
	width: 21cm;
	min-height: 29.7cm;
	padding: 2cm;
	margin: 1cm auto;
	border: 1px #d3d3d3 solid;
	border-radius: 5px;
	background: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>
