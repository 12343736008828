<template>
	<div>
		<notifications></notifications>
		<router-view :key="$route.fullPath"></router-view>
	</div>
</template>

<script>
import NotificationTemplate from './pages/Notifications/NotificationTemplate';
import { BaseAlert } from '@/components';
export default {
	components: {
		BaseAlert
	},
	methods: {
		disableRTL() {
			if (!this.$rtl.isRTL) {
				this.$rtl.disableRTL();
			}
		},
		toggleNavOpen() {
			let root = document.getElementsByTagName('html')[0];
			root.classList.toggle('nav-open');
		}
	},
	computed: {
		notification() {
			return this.$store.getters.notification;
		}
	},
	watch: {
		notification: function() {
			this.$notify({
				message: this.notification.message,
				component: NotificationTemplate,
				/* 	icon: 'tim-icons icon-bell-55', */
				horizontalAlign: 'center',
				verticalAlign: 'top',
				type: this.notification.type,
				timeout: 5000
			});
		}
	},
	created() {
		const currentUrl = window.location.pathname;
		const params = currentUrl.split('/');
	},
	beforeMount() {},
	mounted() {
		this.$watch('$route', this.disableRTL, { immediate: true });
		this.$watch('$sidebar.showSidebar', this.toggleNavOpen);
	},
	beforeCreate() {},

	beforeUpdate() {
		/* this.$store.commit('storeService', this.$route.params.slug); */
	}
};
</script>

<style lang="css">
.spinnerCenter {
	position: absolute;
	height: 100px;
	width: 100px;
	top: 50%;
	left: 50%;
	margin-left: -50px;
	margin-top: -50px;
	font-weight: 600;
}
</style>
