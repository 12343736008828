import VueRouter from 'vue-router';
import routes from './routes';
import store from './../store';

const router = new VueRouter({
    base: ``,
    mode: 'history',
    routes,
    linkExactActiveClass: 'active',
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    const role = store.getters.user.type;

    if (!role &&
        (to.matched.some(record => record.meta.admin) ||
            to.matched.some(record => record.meta.manager) ||
            to.matched.some(record => record.meta.default))
    ) {
        next({
            path: `/${store.getters.service.slug}/${store.getters.app.code}/not-authorized`
        });
    } else if (
        role == 'default' &&
        (to.matched.some(record => record.meta.admin) || to.matched.some(record => record.meta.manager))
    ) {
        next({
            path: `/${store.getters.service.slug}/${store.getters.app.code}/not-authorized`
        });
    } else {
        next();
    }
});
export default router;