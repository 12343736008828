import 'vue-datetime/dist/vue-datetime.css';
import {
    BaseInput,
    BaseTextarea,
    Card,
    BaseDropdown,
    BaseButton,
    SubmitButton,
    BaseCheckbox,
    BaseRadio,
    BaseSwitch,
    Modal
} from '../components/index';
import { Tabs, Tab } from 'vue-tmn-tabs';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import { Datetime } from 'vue-datetime';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
    install(Vue) {
        Vue.component('vtable', VueGoodTable);
        Vue.component('tabs', Tabs);
        Vue.component('tab', Tab);
        Vue.component(BaseInput.name, BaseInput);
        Vue.component(BaseTextarea.name, BaseTextarea);
        Vue.component(Card.name, Card);
        Vue.component(BaseDropdown.name, BaseDropdown);
        Vue.component(BaseButton.name, BaseButton);
        Vue.component(SubmitButton.name, SubmitButton);
        Vue.component(BaseCheckbox.name, BaseCheckbox);
        Vue.component(BaseRadio.name, BaseRadio);
        Vue.component(Modal.name, Modal);
        Vue.component('date-picker', Datetime);
    }
};

export default GlobalComponents;