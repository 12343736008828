import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';
import SampleLayout from '@/layout/service/SampleLayout.vue';
import NotFound from '@/pages/NotFoundPage.vue';
import NotAuthorized from '@/pages/NotAuthorizedPage.vue';
import Root from '@/pages/Root.vue';

// Admin pages
const Admin = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Admin.vue');
const Dashboard = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Dashboard.vue');
const Manager = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Manager.vue');
const Notifications = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Notifications.vue');
const Icons = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Icons.vue');
const Maps = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Maps.vue');
const Typography = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Typography.vue');
const TableList = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/TableList.vue');
const Home = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Home.vue');
const Profile = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Profile.vue');
const Signin = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/SignIn.vue');
const AdminSignin = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/AdminSignin.vue');
const SetPassword = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/SetPassword.vue');
const RecoveryPassword = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/RecoveryPassword.vue');
const Assistenza = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Assistenza.vue');
const Faq = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/Faq.vue');
const ServiceApp = () =>
    import ( /* webpackChunkName: "common" */ '@/pages/ServiceApp.vue');

const routes = [{
    path: '/',
    name: 'root',
    component: SampleLayout,
    children: [{
            path: '/:service',
            name: 'home',
            component: Home
                /*  meta: { admin: true, manager: true } */
        },
        {
            path: '/:service/admin',
            name: 'admin',
            component: Admin,
            meta: { admin: true }
        },
        {
            path: '/:service/:app/profile',
            name: 'userProfile',
            component: Profile,
            meta: { default: true }
        },

        {
            path: '/:service/:app',
            name: 'app',
            component: ServiceApp
        },
        {
            path: '/:service/:app/signin',
            name: 'signin',
            component: Signin
        },
        {
            path: '/:service/:app/admin',
            name: 'AdminSignin',
            component: AdminSignin
        },
        {
            path: '/:service/:app/setPassword',
            name: 'setPassword',
            component: SetPassword
        },
        {
            path: '/:service/:app/rec',
            name: 'recoveryPassword',
            component: RecoveryPassword
        },
        {
            path: '/:service/:app/dashboard',
            name: 'dashboard',
            component: Manager,
            meta: { admin: true, manager: true }
        },
        {
            path: '/:service/:app/faq',
            name: 'Assistenza & FAQ',
            component: Faq
        },
        {
            path: '/:service/:app/privacy',
            name: 'privacy',
            component: Assistenza
        },
        { path: '/:service/:app/not-found', component: NotFound, name: 'not-found' },
        { path: '/:service/:app/not-authorized', component: NotAuthorized, name: 'notAuthorized' }
    ]
}];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;